<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    window.location.href = 'https://rcook.pro'
  }
}
</script>
